<template>
  <div>
    <b-overlay
      :show="isLoading"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <h4>PG PRO</h4>
      <b-card>
        <b-row match-height>
          <b-col
            v-for="item in pgproList"
            :key="item.id"
            sm="4"
            md="3"
            lg="2"
          >
            <b-card
              no-body
              class="border overflow-hidden"
              style="border-radius: 16px; position: relative"
            >
              <div
                style="
                    position: absolute;
                    top: 10px;
                    right: 0;
                    background-color: red;
                    color: white;
                    padding: 4px 8px;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                    font-size: 12px;
                    font-weight: bold;
                    z-index: 10;
                  "
              >
                PG PRO
              </div>

              <img
                class="w-100 h-100"
                :src="`https://play.bkbet365.cc/img/games/imgpg/${item.game_code}.png`"
                style="object-fit: cover"
              >
              <div class="mt-1 text-truncate px-1 pb-1">
                <h6>{{ item.game_name }}</h6>
                <b-form-checkbox
                  v-model="item.pgpro_status"
                  name="check-button"
                  switch
                  :value="1"
                  :unchecked-value="0"
                  @change="PGProchangeStatus(item)"
                />
                <!-- <span> {{ item.pgpro_status == 1 ? 'เปิด':'ปิด' }}</span> -->
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BRow,
  BCol,
  BModal,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BPagination,
  BTable,
  BCard,
  BCardTitle,
  BCardBody,
  BOverlay,
  BIconController,
  BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment-timezone'

export default {
  components: {
    BFormCheckbox,
    BRow,
    BCol,
    BModal,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BPagination,
    BTable,
    BCard,
    BCardTitle,
    BCardBody,
    BOverlay,
    BIconController,
    BButton,
    flatPickr,
  },
  data: () => ({
    pgproList: [],
    isLoading: false,
  }),
  mounted() {
    this.getDataPgpro()
  },
  methods: {
    async PGProchangeStatus(item) {
      this.isLoading = true
      try {
        // console.log(item);

        await this.$http.post('/pgpro/changeStatus', item)

        this.getDataPgpro()
        // console.log(data)
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async getDataPgpro() {
      this.isLoading = true
      try {
        const { data } = await this.$http.get('/pgpro/gamelist')
        this.pgproList = data.Data
        // console.log(data.Data)
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
